/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isBankSelectDisabled: false,
  externalId: '',
  isBankSelected: false,
  isOrderReady: false,
  isOrderConfirm: false,
  isWaitingCardData: false,
  skipBankSelect: false,
  failUrlIfSkipped: '',
  selectedBank: '',
  successUrlV1: '',
  isClickPay: false,
  orderData: {
    amount: '',
    expireAt: -1,
    currency: '',
    cardNumber: '',
    accountNumber: '',
    phoneNumber: '',
    iban: '',
    ifscCode: '',
    cardHolderName: '',
    upiId: '',
    bicCode: '',
  },
}

const slice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setIsWaitingCardData: (state, action) => {
      state.isWaitingCardData = action.payload
    },
    setExternalId: (state, action) => {
      state.externalId = action.payload
    },
    setIsBankSelected: (state, action) => {
      state.isBankSelected = action.payload
    },
    setIsOrderReady: (state, action) => {
      state.isOrderReady = action.payload
    },
    setIsOrderConfirm: (state, action) => {
      state.isOrderConfirm = action.payload
    },
    setOrderData: (state, action) => {
      state.orderData = action.payload
    },
    setSelectedBank: (state, action) => {
      state.selectedBank = action.payload
    },
    setIsBankSelectDisabled: (state, action) => {
      state.isBankSelectDisabled = action.payload
    },
    setSuccessUrlV1: (state, action) => {
      state.successUrlV1 = action.payload
    },
    setIsClickPay: (state, action) => {
      state.isClickPay = action.payload
    },
    setSkipBankSelect: (state, action) => {
      state.skipBankSelect = action.payload
    },
    setFailUrlIfSkipped: (state, action) => {
      state.failUrlIfSkipped = action.payload
    },
  },
})

export const {
  setExternalId,
  setIsBankSelected,
  setIsOrderReady,
  setIsOrderConfirm,
  setOrderData,
  setSelectedBank,
  setIsBankSelectDisabled,
  setSuccessUrlV1,
  setIsWaitingCardData,
  setIsClickPay,
  setSkipBankSelect,
  setFailUrlIfSkipped,
} = slice.actions
export default slice.reducer
